<template>
  <BaseLayout>
    <template #default>
      <template v-if="!shoesDisplayed">
        <div class="col-50 col-center"></div>
        <p class="h3 text-center" v-html="loadingDataText"></p
      ></template>

      <template v-if="shoesDisplayed">
        <h3>
          All your active running shoes that you have used in the last 365 days
        </h3>
        <div class="filter-bar">
          <label for="sortBy">
            sort by

            <select
              name="sortBy"
              id="sortBy"
              v-model="filterOptions.sortBy"
              @change="sortGear()"
            >
              <option value="speed">Speed</option>
              <option value="distance">Distance</option>
              <option value="cadence">Cadence</option>
              <option value="stride">Stride Length</option>
            </select>
          </label>

          <label for="sortDirection">
            from

            <select
              name="sortDirection"
              id="sortDirection"
              v-model="filterOptions.direction"
              @change="sortGear()"
            >
              <option value="1">low to high</option>
              <option value="0">high to low</option>
            </select>
          </label>
          &nbsp;and&nbsp;
          <div class="checkbox">
            <input
              type="checkbox"
              id="trail"
              class="visually-hidden"
              v-model="filterOptions.hideTrailRuns"
              @change="sortGear()"
            />
            <label for="trail" class="checkbox"> hide Trail Runs </label>
          </div>
        </div>
      </template>

      <div class="gear-list" v-if="shoesDisplayed">
        <ShoeList :shoes="shoesDisplayed" @filter-runs="sortGear()"></ShoeList>
        <div class="text-right">
          <a
            class="text-right mt-16 d-inline-block text-small"
            href="https://www.strava.com/settings/gear"
            target="_blank"
            >View on Strava</a
          >
        </div>
      </div>

      <div class="col text-center">
        <button
          class="btn btn-primary btn-with-icon btn-refresh mt-16"
          type="button"
          @click="getGear()"
        >
          <IconComponent :icon="'refreshIcon'" :class="'icon-light'" />
          {{ loadButtonText }}
        </button>
        <p class="font-size-100 font-light mt-16" v-if="lastUpdate">
          Last updated: {{ lastUpdate }}
        </p>
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import ShoeList from "@/components/ShoeList.vue";
export default {
  name: "ShoesOverview",
  components: {
    BaseLayout,
    ShoeList,
  },
  data() {
    return {
      filterOptions: {
        hideTrailRuns: false,
        sortBy: "speed",
        direction: 0,
      },
      shoesDisplayed: null,
    };
  },
  methods: {
    getGear() {
      this.$store.dispatch("getGear");
    },
    sortGear() {
      this.$store.commit("setFilterOptions", this.filterOptions);
      this.shoesDisplayed = this.$store.getters.filteredGear;
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters.isUserLoggedIn;
    },
    gear() {
      return this.$store.getters.getGear;
    },
    loadingDataText() {
      if (this.$store.getters.getStateStatus) {
        return "Due to an update of the tool, it is necessary to reload your data.";
      }

      return "Looks like you haven't loaded the data for your shoes yet.<br /> Click on the button and find out which is your best shoe.";
    },
    loadButtonText() {
      if (this.$store.getters.getStateStatus) {
        return "Update your Data";
      }
      if (this.$store.getters.getGear) {
        return "Update your Data";
      }
      return "Load your Shoes";
    },
    lastUpdate() {
      return this.$store.getters.getLastUpdate;
    },
  },
  watch: {
    gear: {
      handler: function () {
        console.log("gear watcher");
        // as soon as the gear is available sort/filter them and display the result
        // should only happend at first load of data
        this.shoesDisplayed = this.$store.getters.filteredGear;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss"></style>
