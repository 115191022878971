import { createStore } from "vuex";
import axios from "./../../node_modules/axios/";
import authModule from "./modules/auth.js";

const store = createStore({
  modules: {
    auth: authModule,
  },
  state: {
    stateVersion: 1.1,
    lastUpdate: "",
    activities: [],
    gear: null,
    loading: false,
    loadingMessage: "Loading shoes..",
    hideTrailRuns: false,
    filterOptions: {
      hideTrailRuns: false,
      sortBy: "speed",
      direction: 0,
      excludedActivitiesIds: [],
    },
  },
  mutations: {
    removeGear(state) {
      // TODO - rootstate funktioniert nicht?
      state.gear = null;
      localStorage.removeItem("gear");
    },
    addActivitiesToGear(state) {
      // loop trought the activtivies and assign them to the shoes.
      state.activities.forEach((activity) => {
        if (activity.type === "Run") {
          const gear_id = activity.gear_id;
          const shoe = state.gear.find((s) => {
            return s.id === gear_id;
          });

          if (shoe) {
            // skip activity if distance or time is 0
            if (activity.distance === 0 || activity.moving_time === 0) {
              console.log("skip activity");
              return;
            }

            // keep this key items before push into store
            // delete the rest
            const wantedKeys = [
              "average_cadence",
              "average_speed",
              "sport_type",
              "type",
              "workout_type",
              "start_date",
              "name",
              "gear_id",
              "distance",
              "moving_time",
              "id",
            ];

            const cleanActivity = (activity) => {
              const cleaned = {};
              wantedKeys.forEach((key) => {
                if (activity[key] !== undefined) {
                  cleaned[key] = activity[key];
                }
              });
              return cleaned;
            };

            shoe.activities.push(cleanActivity(activity));
          }
        }
      });
      //
      // remove shoes without a activity in the last 365 days
      state.gear = state.gear.filter((shoe) => shoe.activities.length != 0);

      // calculate average pace and cadence and stride after we get the data the first time from strava
      state.gear.forEach((shoe) => {
        if (shoe.activities.length != 0) {
          const activitiesCount = shoe.activities.length;

          // STRIDE LENGTH ----------------
          shoe.activities.map((activity) => {
            // loop through every activity and calculate the stride length
            const cadence = activity.average_cadence * 2;
            activity.average_stride_length = (
              activity.distance /
              (cadence * (activity.moving_time / 60))
            ).toFixed(2);
          });

          const allAverageStrideLength = shoe.activities.reduce(
            (averageStrideLength, activity) => {
              // the calulation for the average pace
              return (
                averageStrideLength + Number(activity.average_stride_length)
              );
            },
            0
          );
          shoe.average_stride_length_overall = (
            allAverageStrideLength / activitiesCount
          ).toFixed(2);
          // ----------------------------------

          // PACE ---------------
          const allAveragePaces = shoe.activities.reduce(
            (averagePaces, activity) => {
              // the calulation for the average pace
              return averagePaces + Number(activity.average_speed);
            },
            0
          );

          shoe.average_speed_overall = (
            allAveragePaces / activitiesCount
          ).toFixed(3);
          // -------------------------

          // CADENCE ---------------
          const allAverageCadence = shoe.activities.reduce(
            (averageCadence, activity) => {
              return averageCadence + Number(activity.average_cadence);
            },
            0
          );

          shoe.average_cadence_overall = (
            (allAverageCadence / activitiesCount) *
            2
          ).toFixed(2);
          // ---------------------------------

          shoe.activitiesCount = shoe.activities.length;
        }
      });

      state.loading = false;
      state.lastUpdate = new Date().toLocaleDateString("de-DE", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },

    setLoadingMessage(state, msg) {
      state.loadingMessage = msg;
    },
    excludeActivity(state, id) {
      const index = state.filterOptions.excludedActivitiesIds.indexOf(id);
      if (index !== -1) {
        // is in array - remove it
        state.filterOptions.excludedActivitiesIds.splice(index, 1);
      } else {
        // is not in array - add it
        state.filterOptions.excludedActivitiesIds.push(id);
      }
    },
    setFilterOptions(state, filterOptions) {
      state.filterOptions.hideTrailRuns = filterOptions.hideTrailRuns;
      state.filterOptions.sortBy = filterOptions.sortBy;
      state.filterOptions.direction = filterOptions.direction;
    },
  },
  actions: {
    async setGear({ state, dispatch }, payload) {
      state.gear = payload;

      if (state.gear.length > 0) {
        await state.gear.forEach((shoe) => {
          shoe.activities = [];
        });

        dispatch("getActivities");
      } else {
        alert(
          "Apparently you haven't added any shoes to your Strava account. "
        );
      }
    },
    getGear({ state, commit, dispatch }) {
      commit("setLoadingMessage", "Loading Shoes...");
      state.loading = true;
      const url = `https://www.strava.com/api/v3/athlete?resource_state=3&access_token=${state.auth.user.access_token}`;

      return axios
        .get(url)
        .then((response) => {
          dispatch("setGear", response.data.shoes);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getActivities({ state, commit, dispatch }, nextPage) {
      const currentDate = Math.floor(Date.now() / 1000);
      // currentDate - days * Second a day
      const yearAgoDate = currentDate - 365 * 86400;
      const resultsPerPage = 100;
      let page = nextPage ? nextPage : 1;

      const url = `https://www.strava.com/api/v3/athlete/activities?before=${currentDate}&after=${yearAgoDate}&page=${page}&per_page=${resultsPerPage}&access_token=${state.auth.user.access_token}`;
      state.loading = true;
      commit("setLoadingMessage", "Loading activities...");
      if (page > 1) {
        commit("setLoadingMessage", "Loading more activities...");
      }
      if (page > 2) {
        commit("setLoadingMessage", "Loading even more ...");
      }
      if (page > 3) {
        commit("setLoadingMessage", "Oh boy, and even more...");
      }
      if (page > 4) {
        commit(
          "setLoadingMessage",
          "Do you do anything else apart from sport?"
        );
      }
      return axios
        .get(url)
        .then((response) => {
          if (response.data.length) {
            // store data and get next page
            state.activities = [...state.activities, ...response.data];

            // if we get less actvities as aspected per page, we are finished
            // Don't make more requests to the API than necessary
            if (response.data.length === resultsPerPage) {
              page = page + 1;
              dispatch("getActivities", page);
            } else {
              commit("addActivitiesToGear");
            }
          } else {
            commit("addActivitiesToGear");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getGearFromLocalStorage({ state }) {
      console.log("try to get Gear Obj FROM local storage");
      if (localStorage.getItem("stateVersion") != state.stateVersion) {
        return;
      }

      if (localStorage.getItem("gear")) {
        console.log("gear found in local storage");
        state.gear = JSON.parse(localStorage.getItem("gear"));
      }

      if (localStorage.getItem("lastUpdate")) {
        state.lastUpdate = localStorage.getItem("lastUpdate");
      }
    },
    storeGearInLocalStorage({ state }) {
      console.log("store Gear Obj in local storage");
      localStorage.setItem("gear", JSON.stringify(state.gear));
      localStorage.setItem("lastUpdate", state.lastUpdate);
      localStorage.setItem("stateVersion", state.stateVersion);
    },
  },
  getters: {
    getGear(state) {
      return state.gear;
    },
    filteredGear(state) {
      if (!state.gear) return;
      // make a copy of state.gear object
      const filteredGear = state.gear.map((o) => ({ ...o }));

      // filter
      // calculate average pace and cadence without trail runs
      // sport_type:"TrailRun"

      // need this global for the activities
      state.hideTrailRuns = state.filterOptions.hideTrailRuns;

      if (
        state.filterOptions.hideTrailRuns ||
        state.filterOptions.excludedActivitiesIds.length
      ) {
        filteredGear.forEach((shoe) => {
          let activitiesCount = 0;
          let allAveragePaces = 0;
          let allAverageCadence = 0;
          let allAverageStrideLength = 0;

          shoe.activities.forEach((activity) => {
            const excludedActivity =
              state.filterOptions.excludedActivitiesIds.includes(activity.id);

            const filterForTrailRun =
              activity.sport_type === "TrailRun" &&
              state.filterOptions.hideTrailRuns
                ? true
                : false;

            if (!excludedActivity && !filterForTrailRun) {
              // ab hier nur, wenn der Lauf nicht gefilterd wird

              // how many activitys for this shoe
              activitiesCount = activitiesCount + 1;

              // add all activity paces
              allAveragePaces =
                allAveragePaces + Number(activity.average_speed);

              // add cadence
              allAverageCadence =
                allAverageCadence + Number(activity.average_cadence);

              // add stride length
              // console.log(activity.average_stride_length);
              allAverageStrideLength =
                allAverageStrideLength + Number(activity.average_stride_length);
            }
          });

          shoe.average_speed_overall = (
            allAveragePaces / activitiesCount
          ).toFixed(3);

          shoe.average_cadence_overall = (
            (allAverageCadence / activitiesCount) *
            2
          ).toFixed(2);

          shoe.average_stride_length_overall = (
            allAverageStrideLength / activitiesCount
          ).toFixed(2);

          shoe.activitiesCount = activitiesCount;
        });
      }

      // Sort
      if (state.filterOptions.sortBy) {
        filteredGear.sort((a, b) => {
          switch (state.filterOptions.sortBy) {
            case "distance":
              return Number(b.distance) - Number(a.distance);
            case "speed":
              /*  if (a.average_speed_overall === undefined) {
                console.log(a.name);
              }
              console.log(a.average_speed_overall); */
              return (
                Number(b.average_speed_overall) -
                Number(a.average_speed_overall)
              );
            case "cadence":
              return (
                Number(b.average_cadence_overall) -
                Number(a.average_cadence_overall)
              );
            case "stride":
              return (
                Number(b.average_stride_length_overall) -
                Number(a.average_stride_length_overall)
              );
          }
        });
      }

      // reverse order
      if (state.filterOptions.direction === "1") {
        filteredGear.reverse();
      }

      // TODO: filter races
      /* 
      'workout_type' : {
        'run' : {
          '0' : 'None',
          '1' : 'Race',
          '3' : 'Workout',
          '2' : 'Long Run'
        }
        'ride' : {
          '10' : 'None',
          '11' : 'Race',
          '12' : 'Workout'
        }
      } */

      return filteredGear;
    },
    isLoading(state) {
      return state.loading;
    },
    getLoadingMessage(state) {
      return state.loadingMessage;
    },
    getStateStatus(state) {
      //check if state in localStorage ist outdated
      // during an update in the App for example
      /*       if (localStorage.getItem("gear") === null) {
        return false;
      }

      if (localStorage.getItem("stateVersion") === state.stateVersion) {
        return true;
      }

      if (
        localStorage.getItem("gear") != null &&
        localStorage.getItem("stateVersion") != state.stateVersion
      ) {
        return true;
      }

      return false; */

      const storedGear = localStorage.getItem("gear");
      const storedStateVersion = localStorage.getItem("stateVersion");

      return (
        storedGear !== null &&
        (storedStateVersion === state.stateVersion ||
          storedGear !== state.stateVersion)
      );
    },
    hideTrailRuns(state) {
      return state.hideTrailRuns;
    },
    getLastUpdate(state) {
      return state.lastUpdate;
    },
  },
});

export default store;
